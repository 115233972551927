import '../src/routes/Navbar/Navbar.css';
import '../src/routes/Banner/Banner.css';
import '../src/routes/MainContent/MainContent.css';
import '../src/routes/BottomSection/BottomSection.css';
import '../src/routes/IWantContent/IWantContent.css';
import '../src/routes/PageDescription/PageDescription.css';
import '../src/routes/Footer/Footer.css';
import '../src/routes/descriptions/Description.css';
import '../src/routes/SubMenu/SubMenu.css';
import '../src/routes/OfficeBody/OfficeBody.css';
import '../src/routes/OfficeBody/TeamDetails/TeamDetails.css';
import '../src/routes/DraBody/DraBody.css';
import '../src/routes/DraBody/DraSubmenu/DraSubMenu.css';
import '../src/routes/AdAnBody/AdAnBody.css';
import '../src/routes/NAAA/Naaa.css';
import '../src/routes/CasBody/CasBody.css';
import '../src/routes/DataResources/DataResources.css';

export { default } from '@abyss/core/next-app';
